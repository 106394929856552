import React, {FC, useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchCampaigns, setFilterParams} from "../../../store/campaign/campaignSlice";
import {DataTable, PageContent, PageContentContainer, PageHeader, SearchPanel, Spinner} from "../../common";
import {defaultTheme} from "../../../themes/main";
import {NewCampaignButton} from "./styled";

const columns = [
    {
        name: 'CAMPAIGN NAME',
        alias: 'title',
        selector: (row: any) => row.title,
        sortable: true,
        cell: (row: any) =><div>
            <Link to={`/campaign/${row.id}`}>
                <span style={{color: defaultTheme.greenColor}}>{row.title}</span>
            </Link>
        </div>,
        style: {
            color: defaultTheme.greenColor,
        }
    },
    {
        name: 'STATUS',
        alias: 'is_active',
        selector: (row: any) => row.is_active ? 'Active' : 'In-Active',
        sortable: true,
        width: '160px',
    },
];

const Campaigns: FC = () => {

    const dispatch = useAppDispatch();
    const didMountRef = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const {items, isLoading, listFilters, pagination} = useAppSelector(state => state.campaign);
    const keepFilters = location.state?.keepFilters || false;

    useEffect(() => {
        didMountRef.current = true;
        if (!keepFilters) handleReset();
    }, []);

    useEffect(() => {
        if (didMountRef.current) {
            dispatch(fetchCampaigns(listFilters));
        }
    }, [listFilters]);

    const handleReset = () => {
        dispatch(setFilterParams({...listFilters, page: 1, query: ''}));
    };

    const handleChangePage = (page: number) => {
        dispatch(setFilterParams({...listFilters, page}));
    };

    const handleSort = (order_by: string, order_dir: string) => {
        dispatch(setFilterParams({...listFilters, order_by, order_dir, page: 1}));
    };

    return (
        <PageContentContainer>
            <PageHeader>
                <Spinner isLoading={isLoading}/>
                <SearchPanel
                    title={'Campaign Management'}
                >
                    <NewCampaignButton
                        title={'+ Add a New Campaign'}
                        width={230}
                        onClick={()=>navigate('/campaign/create')}
                    />
                </SearchPanel>
            </PageHeader>
            <PageContent>
                <DataTable
                    data={items}
                    columns={columns}
                    currentPage={pagination.current_page}
                    paginationTotalRows={pagination.total}
                    paginationPerPage={pagination.per_page}
                    lastPage={pagination.last_page}
                    from={pagination.from}
                    to={pagination.to}
                    onChangePage={handleChangePage}
                    onSort={handleSort}
                    pagination={true}
                />
            </PageContent>
        </PageContentContainer>
    );
};

export default Campaigns;
